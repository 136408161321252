<template>
  <div class="star">
    <span
      class="star-item"
      v-for="(item, index) in starList"
      :key="index"
      :class="item"
      @click="starClick(index)"></span>
  </div>
</template>
<script>
  export default {
    props: {
      value: {
        type: Number || String,
        default: 0,
      },
      readonly: Boolean,
    },
    computed: {
      starList() {
        const result = [];
        const score = Math.floor(this.value);
        // 先将on添加到list中
        for (let i = 0; i < score; i++) {
          result.push('on');
        }
        // 若不满5个，剩下的添加off直到满5个为止
        for (let i = result.length; i < 5; i++) {
          result.push('off');
        }
        return result;
      },
    },
    methods: {
      starClick(index) {
        if (!this.readonly) {
          this.$emit('input', index + 1);
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .star {
    display: flex;
    align-items: center;
  }
  .star-item {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url('~@/assets/images/order-star.svg') no-repeat center center;
    background-size: 100% 100%;

    &.on {
      background-image: url('~@/assets/images/order-star-active.svg');
    }
    &.off {
      background-image: url('~@/assets/images/order-star.svg');
    }
  }
</style>
