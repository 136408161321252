<template>
  <scroll-layout class="details">
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <template v-for="(button, index) in pageButtons">
          <ykc-button
            :key="index"
            v-if="button.enabled"
            v-bind="button"
            @click="button.handleClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
      </div>
    </template>
    <!-- <Reply :id="evaluateId" /> -->
    <ykc-detail :titles="['订单信息']" class="detail-box">
      <div slot="cvBody">
        <div class="form-detail flex">
          <ykc-form :label-position="'left'" label-width="68px" class="list-left">
            <ykc-detail-item :label="'电站名称：'">
              <span>{{ dealData(detailData.stationName) }}</span>
            </ykc-detail-item>
            <ykc-detail-item :label="'充电单号：'">
              <span>{{ dealData(detailData.tradeSeq) }}</span>
            </ykc-detail-item>
            <ykc-detail-item :label="'用户账号：'">
              <span>{{ dealData(detailData.userAccount) }}</span>
            </ykc-detail-item>
            <ykc-detail-item :label="'用户姓名：'">
              <span>{{ dealData(detailData.nickName) }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="显示状态">
              {{ detailData.displayStatus === 1 ? '显示' : '隐藏' }}
            </ykc-detail-item>
          </ykc-form>
        </div>
      </div>
    </ykc-detail>
    <ykc-detail :titles="['评价信息']">
      <div slot="cvBody">
        <div class="form-detail flex">
          <ykc-form :label-position="'left'" label-width="68px" class="list-left">
            <ykc-detail-item :label="'评价时间：'">
              <span>{{ dealData(detailData.createdTime) }}</span>
            </ykc-detail-item>
            <ykc-detail-item :label="'用户评分：'">
              <StarScore :value="detailData.userScore || 0" :readonly="true" />
            </ykc-detail-item>
            <ykc-detail-item :label="'评价内容：'">
              <span>{{ dealData(detailData.evaluate) }}</span>
            </ykc-detail-item>
            <ykc-detail-item :label="'附件：'">
              <div class="pics-box" v-if="detailData.imgUrls && detailData.imgUrls.length">
                <el-image
                  v-for="(url, index) in detailData.imgUrls"
                  :src="url"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :zoom-rate="1.2"
                  :max-scale="7"
                  :min-scale="0.2"
                  :preview-src-list="detailData.imgUrls"
                  :initial-index="4"
                  fit="cover" />
              </div>
              <div v-else>——</div>
            </ykc-detail-item>
            <template v-if="detailData.replyStatus === 1">
              <ykc-detail-item :label="'回复时间：'">
                <span>{{ dealData(detailData.replyDate) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'修改人：'">
                <span>{{ dealData(detailData.replyPerson) }}</span>
              </ykc-detail-item>
              <ykc-detail-item :label="'回复内容：'">
                <span>{{ dealData(detailData.reply) }}</span>
              </ykc-detail-item>
            </template>
          </ykc-form>
        </div>
      </div>
    </ykc-detail>
  </scroll-layout>
</template>

<script>
  import StarScore from './StarScore.vue';
  import { orderPage } from '@/service/apis';

  export default {
    data() {
      return {
        evaluateId: '',
        detailData: {},
      };
    },
    created() {
      this.evaluateId = this.$route.query.evaluateId;
      this.getOrderDetail();
    },
    computed: {
      pageButtons() {
        return [
          {
            text: '返回',
            type: 'plain',
            enabled: true,
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ];
      },
    },
    components: {
      StarScore,
    },
    methods: {
      getOrderDetail() {
        orderPage
          .orderEvaluateDetail({
            evaluateId: this.evaluateId,
          })
          .then(res => {
            this.detailData = res;
          })
          .catch(err => {
            console.log(err);
          });
      },
      dealData(value) {
        if (value !== undefined && value !== null && value !== '') {
          return value;
        }
        return '——';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .detail-box {
    margin-bottom: 16px;
  }
  .pics-box {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
</style>
